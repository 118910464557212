<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-05 20:39:21
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-09-26 11:41:05
-->
<template>
  <div>
    <modal :visible="visible" title="订单取消原因" @close="handleClose">
      <div class="modal-body">
        <el-form class="modal-form" ref="ruleForm" :model="form" :rules="rules">
          <el-form-item label="取消原因" prop="type">
            <el-select
              class="form-select"
              v-model="form.type"
              placeholder="请选择拒接原因"
            >
              <el-option label="不会安装" value="不会安装"></el-option>
              <el-option label="安装距离太远" value="距离太远"></el-option>
              <el-option label="安装条件复杂" value="安装条件复杂"></el-option>
              <el-option label="安装价格低" value="安装价格低"></el-option>
              <el-option label="安装地点要求严格" value="安装地点要求严格"></el-option>
              <el-option label="个人原因" value="个人原因"></el-option>
              <el-option label="其他" value="其他"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              class="form-textarea"
              type="textarea"
              :rows="5"
              v-model="form.desc"
              placeholder="请填写备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="onSubmit">
          确 定
        </el-button>
      </span>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
export default {
  components: {
    Modal
  },
  data() {
    return {
      visible: false,
      form: {
        type: "",
        desc: ""
      },
      orderId: null,
      rules: {
        type: [
          {
            required: true,
            message: "请选择拒接原因",
            trigger: "change"
          }
        ]
      }
    };
  },
  mounted() {},
  methods: {
    init(id) {
      this.orderId = id;
      this.visible = true;
    },
    handleClose() {},
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.rejectedSign();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * 签收订单
     */
    rejectedSign() {
      let params = {
        id: this.orderId,
        type: 2,
        why: this.form.type,
        remarke: this.form.desc
      };
      const loading = this.$loading({
        lock: true,
        text: "取消中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .installOrderCancel(params)
        .then(() => {
          this.$message({
            message: "恭喜您，取消成功！",
            type: "success"
          });
          this.orderId = null;
          this.visible = false;
          this.$parent.initInstallationOrder();
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    }
  }
};
</script>

<style lang="less" scoped></style>
